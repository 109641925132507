//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

$badge-font-weight:                   500;
$offcanvas-transition-duration:       0.2s;
$drawer-transition-speed:             0.2s;
$drawer-overlay-animation-speed:      3s;

//$font-size-base: 1rem; // Assumes the browser default, typically `13px`
//$h1-font-size:  $font-size-base * 1.75; // 22.75px
//$h2-font-size:  $font-size-base * 1.5; // 19.50px
//$h3-font-size:  $font-size-base * 1.35; // 17.55px
//$h4-font-size:  $font-size-base * 1.25; // 16.25px
//$h5-font-size:  $font-size-base * 1.15; // 14.95px
//$h6-font-size:  $font-size-base * 1.075; // 13.97px
$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 1.6;
$h2-font-size:                $font-size-base * 1.4;
$h3-font-size:                $font-size-base * 1.3;
$h4-font-size:                $font-size-base * 1.2;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;


$gray-100: #F9F9F9;
$gray-200: #F4F4F4;
$gray-300: #E1E3EA;
$gray-400: #B5B5C3;
$gray-500: #A1A5B7;
$gray-600: #7E8299;
$gray-700: #5E6278;
$gray-800: #3F4254;
$gray-900: #181C32;

// Theme colors
// Primary
$primary:       					#3E97FF;
$primary-active:    			#2884EF;
$primary-light:    				#EEF6FF;
$primary-light-dark:			#212E48;
$primary-inverse:  				#FFFFFF;

// Secondary colors
$secondary: 					    $gray-200;
$secondary-active: 				$gray-300;
$secondary-light: 				$gray-100;
$secondary-inverse:				$gray-800;

// Success
$success:       					#50CD89;
$success-active:    			#47BE7D;
$success-light:    				#E8FFF3;
$success-light-dark:			#1C3238;
$success-inverse:  				#FFFFFF;

// Info
$info:       							#7239EA;
$info-active:    					#5014D0;
$info-light:    					#F8F5FF;
$info-light-dark: 				#2F264F;
$info-inverse:  					#FFFFFF;

// Danger
$danger:       						#F1416C;
$danger-active:    				#D9214E;
$danger-light:    				#FFF5F8;
$danger-light-dark:				#3A2434;
$danger-inverse:  				#FFFFFF;

// Warning
$warning:       					#FF8F00;
$warning-active:    			#F1BC00;
$warning-light:    				#FFF8DD;
$warning-light-dark:			#392F28;
$warning-inverse:  				#FFFFFF;
