@import "fortee/scss/_init";
@import "conference";

body.dashboard.index, body.dashboard.explore, body.dashboard.sponsors {
  #form-signup { display:none; }

  .app-content {
    padding-top:0 !important;
  }
  .app-container {
    padding:0 !important;
  }
  .app-footer {
    padding:0 30px;
  }
  .app-header {
    padding:0 60px;
  }

  div.hero-container {
    margin:0;
    padding:40px 60px;
    background-color:#1e1e2d;
    position: relative;

    div.text-element {
      color:#f0f0f0;
      width: 100%;
    }
  }

  div.my-conference-groups-container {
    background-color: $gray-100;
  }

  div.my-conference-groups {
    padding:20px 10px 10px 10px;
    position: sticky;
    top: 62px;

    h1 {
      font-size:1rem;
      font-weight:normal;
      margin-bottom:10px;
    }
    ul {
      list-style-type: none;
      margin:0 0 20px 10px;
      padding:0;
      li {
        margin-bottom:10px;
        img {
          width:30px;
          height:30px;
          margin-right:5px;
          border-radius:50%;
          border:1px solid $gray-300;
        }
      }
    }
  }

  div.card.feature {
    margin-bottom:20px;

    div.card-img-top {
      width:100%;
      height:200px;
    }

    &.cfp {
      div.card-img-top {
        background: url('/images/INA85_dinamicmaiku_TP_V.jpg') no-repeat center center / cover;
      }
    }
    &.relationship {
      div.card-img-top {
        background: url('/images/macbook1680_TP_V.jpg') no-repeat center center / cover;
      }
    }
    &.integration {
      div.card-img-top {
        background: url('/images/PAK150307492097_TP_V.jpg') no-repeat center center / cover;
      }
    }

    div.text {
      height:100%;
      background-color:rgba(0, 0, 0, 0.7);
      color:white;

      font-size:32px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }


  div.conferences {
    div.conference {
      margin-bottom:20px;

      div.card-img-top {
        img {
          width:100%;
        }
        border-bottom: 1px solid $gray-lighter;
      }
      div.card-body {
        div.term {
          color: $orange;
          font-size: 0.8rem;
          margin-bottom:5px;
          font-weight:bold;
        }
        a.name {
          //color: $text-color;

          &:hover {
            color: $primary;
          }
        }

        ul.links {
          list-style-type: none;
          margin:20px 0 0 0;
          padding:0;

          li {
            float:left;
            margin-right:5px;
            margin-bottom:10px;

            background-color:#f0f0f0;
            border-radius: 20px;
            padding:2px 10px;

            font-size:small;

            color: $gray-dark;
            a {
              color: $gray-dark;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          &:after {
            display: block;
            content: "";
            clear: both;
          }
        }
      }
    }
  }

}
