@import "fortee/scss/_init";

body.dashboard.conference {
  a.conference-following {
    &::after {
      content: "フォロー解除する";
      display: none;
    }

    &:hover {
      &::after {
        display: inline;
      }
      .default {
        display: none;
      }
    }
  }
  div.banner {
    margin-bottom:10px;
    img {
      width:100%;
      border-radius: 4px;
    }
  }
  h1 {
    font-size: 2.5rem;
  }

  #show-all-news {
    margin-bottom:10px;
    display:inline-block;
  }
  #news-all {
    display: none;
  }
  ul.news {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 5px;

      div.published {
        font-size: 0.8rem;
        color: $gray-dark;
      }
      div.title {
        margin-bottom: 3px;
      }
      div.body {
        margin-left:20px;
        color: $gray-dark;
      }
    }
  }
  div.date {
    font-size: 1.2rem;
  }
  div.heading {
    color: $gray;
    font-size: 0.8rem;
    font-weight: bold;
  }
  div.note {
    margin-top:5px;
    color: $gray-dark;
    font-size: 0.8rem;
  }

  div.timeline {
    div.line {
      border-right:4px solid $gray-light;
      width:18px;
      margin-right:14px;
    }
    div.circle {
      background-color:$gray;
      border-radius:50%;
      width:16px;
      height:16px;
      margin-left:8px;
      margin-top:8px;
    }
    div.milestone {
      margin-left:10px;

      div.datetime {
        color: $gray;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
      }
      div.title {

      }
    }
  }
}
